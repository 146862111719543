import { keyframes, styled } from "styled-components";

import ufoSprite from "../assets/loading-elements/ufo.png";
import logoSprite from "../assets/loading-elements/splash-logo-crash.png";
import planetASprite from "../assets/loading-elements/planet-1.png";
import planetBSprite from "../assets/loading-elements/planet-2.png";
import backgroundSprite from "../assets/loading-elements/splash-bg-crash-cow.jpg";
import cowSprite from "../assets/loading-elements/cow.png";

export const ScaleIn = keyframes`
    0% { transform: scale(0) }
    100% { transform: scale(0.7) }
`;

export const FlyInFromBottom = keyframes`
    0% { transform: scale(0.4) translate(0px, 2000px); }
    100% { transform: scale(0.4) translate(0px, 562px); }
`;

export const ShipSway = () => keyframes`
    0% { transform: translate(-4px, 4px); rotate(-3deg) }
    20% { transform: translate(${Math.random() > 0.5 ? Math.floor(Math.random()) * 4 : Math.floor(Math.random() * -4)}px, 0px) rotate(-2deg)}
    40% { transform: translate(${Math.random() > 0.5 ? Math.floor(Math.random()) * 4 : Math.floor(Math.random() * -4)}px, 0px) rotate(-1deg)}
    60% { transform: translate(${Math.random() > 0.5 ? Math.floor(Math.random()) * 4 : Math.floor(Math.random() * -4)}px, 0px) rotate(0deg)}
    80% { transform: translate(${Math.random() > 0.5 ? Math.floor(Math.random()) * 4 : Math.floor(Math.random() * -4)}px, 0px) rotate(1deg)}
    100% { transform: translate(-4px, 4px); rotate(3deg) }
`;

const Pulsate = keyframes`
    0% { transform: scale(1); }
    100% { transform: scale(1.1); }
`

export const CowSway = keyframes`
    0% { transform: rotate(0deg); }
    50% { transform: rotate(-10deg); }
    100% { transform: rotate(10deg); }
`;

const getFlipped = () => Math.random() > 0.5 ? 1 : -1; 
const randomInt = () => getFlipped() * 10 + Math.random() * 10 + "px";
export const PlanetsDance = () => {
    
    const randXA = randomInt();
    const randYA = randomInt();
    const randXB = randomInt();
    const randYB = randomInt();
    return keyframes`
    0% { transform: translate(${randXA}, ${randYA}); }
    50% { transform: translate(0px, 0px); }
    100% { transform: translate(${randXB}, ${randYB}); }
`;
}

export const ScaleInMore = keyframes`
    0% { transform: scale(0) }
    100% { transform: scale(1) }
`;

export const StarsAnimation = keyframes`
    0% { opacity: 0.5 }
    100% { opacity: 1 }
`;

const Background = styled.div`
    background-image: url(${backgroundSprite});
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
`;

const StyledSplash = styled.div`
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Sprite = styled.div`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 1;
`;

const UFO = styled(Sprite)`
    background-image: url(${ufoSprite});
    max-width: 100%;
    width: 734px;
    height: 929px;
    animation: ${ScaleIn} 500ms cubic-bezier(.81,1.02,.45,1.66);
    transform: scale(0);
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
    position: absolute;
    top: 0;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 929px;
    display: flex;
    justify-content: center;
`;

const LogoContainer = styled.div`
    animation: ${Pulsate} 1s infinite alternate ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-delay: 1000ms;
`;

const Logo = styled(Sprite)`
    background-image: url(${logoSprite});
    max-width: 100%;
    width: 734px;
    height: 929px;
    animation: ${ScaleInMore} 500ms cubic-bezier(.81,1.02,.45,1.66);
    transform: scale(0);
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
    position: absolute;
    top: 0;
`;

const PlanetA = styled(Sprite)`
    background-image: url(${planetASprite});
    max-width: 100%;
    width: 734px;
    height: 929px;
    position: absolute;
    transform: translate(-410px, -280px) scale(0.3);
    top: 0px;
    left: 0px;
    background-position: top left;
`;

const PlanetB = styled(Sprite)`
    background-image: url(${planetBSprite});
    max-width: 100%;
    width: 734px;
    height: 929px;
    position: absolute;
    transform: translate(270px, 270px) scale(0.2);
    bottom: 50px;
    right: -50px;
`;

const Cow = styled(Sprite)`
    background-image: url(${cowSprite});
    width: 734px;
    height: 929px;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    transform: scale(0.4) translate(0px, 2000px);
    animation: ${FlyInFromBottom} 500ms ease-out;
    animation-delay: 1700ms;
    animation-fill-mode: forwards;
    background-position: top;
`;

const PlanetContainer = styled.div<{ $delay?: number; }>`
    animation: ${PlanetsDance()} 1s infinite alternate ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
    animation-delay: ${props => props.$delay}ms;
`;

const ShipContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    animation: ${ShipSway()} 3s infinite alternate ease-in-out;
    display: flex;
    transform-origin: 50% 250px;
    justify-content: center;
`;

const CowContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    animation: ${CowSway} 2s infinite alternate ease-in-out;
    animation-delay: 2300ms;
    display: flex;
    transform-origin: 50% 660px;
    justify-content: center;
`;

const Splash: React.FC<SplashProps> = ({

    ...props
}) => {

    return (
        <StyledSplash>
            <Background />
            <Container>
                <ShipContainer><UFO/></ShipContainer>
                <PlanetContainer $delay={Math.random() * 512}><PlanetA /></PlanetContainer>
                <PlanetContainer $delay={Math.random() * 244}><PlanetB /></PlanetContainer>
                <LogoContainer><Logo /></LogoContainer>
                <CowContainer><Cow /></CowContainer>
            </Container>
        </StyledSplash>
    )
};

interface SplashProps {
    
}

export default Splash;