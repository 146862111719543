import { useLayoutEffect, useState } from "react";
import { keyframes, styled } from "styled-components";

const ScrollAnimation = (animationHeight: number) => keyframes`
    0% { background-position: 50% 0%; }
    100% { background-position: 50% ${animationHeight}px; }
`;

const ScrollA = styled.div<{$rate: number, $scale: number, $x: number, $y: number, $BackgroundImage: string, $animationPaused: boolean}>`
    background-image: url(${ props => props.$BackgroundImage });
    position: absolute;
    width: 1920px;
    animation: ${props => ScrollAnimation(Math.max(1440, 1440 / props.$scale))} ${ props => props.$rate }s linear infinite;
    height: ${props => Math.max(1440, 1440 / props.$scale)}px;
    transform: scale(${ props=> props.$scale });
    transform-origin: top left;
    animation-play-state: ${props => props.$animationPaused ? "paused" : "running"};
    background-size: cover;
`;

const ParallaxBackground: React.FC<ParallaxBackgroundProps> = ({
    rate,
    backgroundImage,
    frequency,
    animationPaused,
    ...props
}) => {
    let [ scale, setScale ] = useState<number>(1);
    let [ x, setX ] = useState<number>(1);
    let [ y, setY ] = useState<number>(1);

    useLayoutEffect(() => {
        
        function resize() {
            setScale(window.innerWidth / 1920);
            setX(window.innerWidth / 2);
            setY(window.innerHeight / 2);
        }

        setTimeout(() => {
            resize();
        }, 1000)
        
        window.addEventListener("resize", resize);
    }, []);

    return (
        <ScrollA $BackgroundImage={backgroundImage} $animationPaused={animationPaused} $x={x} $y={y} $scale={scale} $rate={rate}></ScrollA>
    )
};

interface ParallaxBackgroundProps {
    rate: number
    backgroundImage: string,
    frequency: number,
    animationPaused: boolean
}

export default ParallaxBackground;