import { styled } from "styled-components";

const StyledDiv = styled.div`
    justify-content: space-between;
    display: flex;
    width: 98%;
    height: 30px;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.15);
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
`;
const StyledContent = styled.div`
    margin-top: -3px;
    text-shadow: 1px 1px #000000;
`;

const LeftContainer = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    color: var(--primary);
`;

const BetLine: React.FC<MultiplierProps> = ({
    username,
    content,
    isPlayer
}) => {

    return (
        <StyledDiv>
            
            <LeftContainer>
                <p style={{ textShadow: "1px 1px #000000", opacity: isPlayer ? 0.8 : 0.6, overflow: "hidden", textOverflow: "ellipsis", color: isPlayer ? "gold" : "white" }}>{username}</p>
            </LeftContainer>            
            <StyledContent>{content}</StyledContent>
        </StyledDiv>
    )
};

interface MultiplierProps {
    username: string;
    content: string;
    isPlayer: boolean;
}

export default BetLine;