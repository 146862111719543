import { IEvent } from "@lindar-joy/goosicorn-quickies-library";

export interface IShowEvent extends IEvent {
    engineRoundId: string
    currentState: GAME_STATE,
    results: IResults,
    thereArePlacedBets: boolean,
    defaultBet: number;
}

export interface IAvailableActionsEvent extends IEvent {
    engineRoundId: string;
}

export interface IUpdateGameplayEvent extends IEvent {
    engineRoundId: string;
}

export interface IResults {
    flyingDistance: number;
    engineRoundId: string,
    engineRoundTime: number
}




export enum GAME_STATE {
    FLYING = "FLYING",
    CRASHED = "CRASHED",
    PLACE_BET = "PLACE_BET"
}

export interface IGamePlayEvent extends IEvent {
    timer: number;
    result: IResults,
    engineRoundId: string,
    enginePhase: GAME_STATE,
    resultCollection: any
}
