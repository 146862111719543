import { keyframes, styled } from "styled-components";


const StyledDiv = styled.div`
    position: absolute;
    width: 100%;
    
    text-align: center;
    top: 40px;
    font-size: 26px;
    font-variant-numeric: tabular-nums;
    pointer-events: none;
    background-color: var(--primary);
`;

const TakeOffTimer: React.FC<TakeOffTimerProps> = ({
    takeOffTime,
    ...props
}) => {

    return (
        <StyledDiv>
            { `TAKE OFF IN ${takeOffTime.toFixed(1)} SECONDS` }
        </StyledDiv>
    )
};

interface TakeOffTimerProps {
    takeOffTime: number
}

export default TakeOffTimer;