// @ts-ignore
import styled, { Keyframes, keyframes } from 'styled-components';
import BackgroundImage from "./assets/background/layer0-gradient.png";
import LogoTexture from "./assets/logo-crash.png";


export const Logo = styled.div`
    background-image: url(${LogoTexture});
    width: 626px;
    height: 370px;
    background-size: contain;
    position: absolute;
    top: -290px;
    background-repeat: no-repeat;
    z-index: -1;
`;

export const PreviousResults = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    align-items: flex-end;
    justify-content: space-evenly;
`;

export const PlayerCount = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 4px;
    align-items: center;
`;

export const BetsContainer = styled.div`
    width: 646px;
    height: 180px;
    position: absolute;
    overflow-y: scroll;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 160px;
    left: 50%;
    margin-left: -323px;
    z-index: 70;
    gap: 7px;
    scrollbar-width: none;
    --mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
    -webkit-mask: var(--mask); 
    mask: var(--mask);

    @media (max-width: 680px) {
        width: 100%;
        margin-left: -50%;
        margin-bottom: 100px;
    }
`;

export const Background = styled.div`
    background-image: url(${BackgroundImage});
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    
`;

export const GameContainer = styled.div`
    display: flex;
    padding: 56px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    flex-shrink: 0;
    width: 100%;
`;