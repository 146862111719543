import { styled } from "styled-components";


import { SpineStage, SpineStageRef, useRequestAnimationFrame } from "@lindar-joy/goosicorn-quickies-library";
import { memo, useEffect, useRef, useState } from "react";
import { createEvent } from "react-event-hook";

const StyledStage = styled(SpineStage)<{$scale: number, $x: number, $y: number}>`
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transform-origin: center;
    transform: scale(${ props => props.$scale }) translateX(${props => props.$x / devicePixelRatio}px) translateY(${props => props.$y / devicePixelRatio}px);
    width: 1080px;
    height: 1080px;
    top: calc(50% - 540px);
    left: calc(50% - 540px);
`;

const SpaceShip: React.FC<RocketProps> = memo(
    props => {
    const stageRef = useRef<SpineStageRef>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [scale, setScale] = useState<number>(1);
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);

    useEffect(() => {

        if (loaded) {
            const stage = stageRef.current as SpineStageRef;    
            
            stage.stop("ufo-travel");
            stage.stop("cow-travel");
            stage.stop("ufo-crash");
            stage.stop("cow-crash");
            stage.stop("ufo-reset");
            stage.stop("cow-reset");
            stage.stop("cow-hover-0");
            stage.stop("cow-hover-1");
            stage.stop("cow-hover-2");
            stage.stop("explosion");
            stage.stop("ufo-hover");
            
            stage.setVisible("ufo-travel", false);
            stage.setVisible("cow-travel", false);
            stage.setVisible("ufo-crash", false);
            stage.setVisible("cow-crash", false);
            stage.setVisible("ufo-reset", false);
            stage.setVisible("cow-reset", false);
            stage.setVisible("cow-hover-0", false);
            stage.setVisible("cow-hover-1", false);
            stage.setVisible("cow-hover-2", false);
            stage.setVisible("ufo-hover", false);

            stage.setVisible("explosion", false);
            
            if (props.state === "FLYING") {
                stage.setVisible("ufo-reset", false);
                stage.stop("ufo-reset");
                stage.setVisible("ufo-travel", true);
                stage.play("ufo-travel", "animation", true);
                stage.setVisible("cow-travel", true);
                stage.play("cow-travel", "animation", true);
            } else if(props.state === "CRASHED") {
                stage.setVisible("ufo-crash", true);
                stage.play("ufo-crash", "animation", false);
                stage.setVisible("explosion", true);
                stage.play("explosion", "big-win", false);
                (async () => {
                    stage.setVisible("cow-crash", true);
                    await stage.play("cow-crash", "animation", false);
                    stage.setVisible("cow-crash", false);
                    stage.setVisible("cow-hover-1", true);
                    stage.play("cow-hover-1", "animation", false);
                })();
            } else if (props.state === "PLACE_BET") {
                stage.setVisible("ufo-reset", true);
                (async () => {

                    await stage.play("ufo-reset", "animation", false);
                    stage.setVisible("ufo-reset", false);
                    stage.setVisible("ufo-hover", true);
                    stage.play("ufo-hover", "animation", true);
                })();
                
                stage.setVisible("cow-reset", true);
                (async () => {
                    await stage.play("cow-reset", "animation", false);
                    stage.setVisible("cow-reset", false);
                    const animationIndex = Math.floor(Math.random() * 3);
                    stage.setVisible(`cow-hover-${animationIndex}`, true);
                    stage.play(`cow-hover-${animationIndex}`, "animation", true);
                })()

            }
        }
    }, [ loaded, props.state ]);
    const shipScale = devicePixelRatio === 1 ? 1 : 1.5;
    useRequestAnimationFrame(() => {
        const scale = window.innerHeight / 1080;
        setScale(scale * devicePixelRatio);
        setX((-540 / scale) + ((window.innerWidth / scale) / 2));
        setY((-540 / scale) + ((window.innerHeight / scale) / 2));    
    });
    
    return (
        
        <StyledStage width={1080} height={1080} $scale={ 1 } $x={0} $y={-120} stageId="ufo-travel" spineObjects={[
            { id: "ufo-travel", path: "assets/spine/ufo-travel", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "ufo-crash", path: "assets/spine/ufo-crash", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "ufo-reset", path: "assets/spine/ufo-reset", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "ufo-hover", path: "assets/spine/ufo-hover", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "explosion", path: "assets/spine/explosion", x: 540 * devicePixelRatio, y: -350 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-travel", path: "assets/spine/cow-travel", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-crash", path: "assets/spine/cow-crash", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-reset", path: "assets/spine/cow-reset", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-hover-0", path: "assets/spine/cow-hover-0", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-hover-1", path: "assets/spine/cow-hover-1", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            { id: "cow-hover-2", path: "assets/spine/cow-hover-2", x: 540 * devicePixelRatio, y: -470 * devicePixelRatio, scale: {x: shipScale, y: shipScale} },
            
        ]}  visible={ true } onLoad={() => {
            emitShipLoad();
            setLoaded(true);
            // @ts-ignore
        }} {...props} ref={stageRef} />
    )

}, (previousProps, nextProps) => {
    console.log(previousProps, nextProps);
    return previousProps.state === nextProps.state;
});

interface RocketProps {
    state: string
}
export const { useShipLoadListener, emitShipLoad } = createEvent("ship:load")<void>();
export default SpaceShip;