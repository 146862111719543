import styled from "styled-components";

import { GameHistoryRow } from "@lindar-joy/goosicorn-quickies-library";

interface ICrashGameRoundDetails {
    betAmount: number;
    multiplier: number;
    winAmount: number;
    gameRoundId: string;
    engineRoundId: string;
}

export const GameHistoryRenderer = (gameRound: ICrashGameRoundDetails) => {
    return <GameHistoryRow><div>Multiplier</div><div>{ (gameRound.multiplier / 100) || "N/A" }</div></GameHistoryRow>;
}
