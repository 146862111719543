import { SpineStage, SpineStageRef } from "@lindar-joy/goosicorn-quickies-library";
import { memo, useEffect, useRef, useState } from "react";
import { keyframes, styled } from "styled-components";
import MultiplierBackground from "./MultiplierBackground";


const StyledDiv = styled.div<{$visible: boolean}>`
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 40px;
    font-size: 26px;
    font-variant-numeric: tabular-nums;
    pointer-events: none;
    display: ${ props => props.$visible ? "block" : "none" }
`;

const StyledStage = styled(SpineStage)<{$scale: number, $x: number, $y: number, $width: number, $height: number}>`
    position: absolute;
    z-index: 0;
    pointer-events: none;
    transform-origin: center;
    transform: scale(${ props => props.$scale }) translateX(${props => props.$x}px) translateY(${props => props.$y}px);
    width: ${ props => props.width }px;
    height: ${ props => props.height }px;
`;

const FXStage = styled(SpineStage)<{$scale: number, $x: number, $y: number, $width: number, $height: number}>`
    position: absolute;
    z-index: 0;
    pointer-events: none;
    transform-origin: center;
    transform: scale(${ props => props.$scale }) translateX(${props => props.$x}px) translateY(${props => props.$y}px);
    width: ${ props => props.$width }px;
    height: ${ props => props.$height }px;
`;

const StyledMultiplier = styled.div`
    background-color: var(--primary);
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 2px;
`;

const Multiplier: React.FC<MultiplierProps> = memo(props => {
    return (
        <StyledDiv $visible={props.visible}>
            
            <StyledMultiplier>{ `${(props.multiplierValue / 100).toFixed(2)}X` }</StyledMultiplier>
        </StyledDiv>
    )
}, (prevProps, nextProps) => { return prevProps.visible === nextProps.visible && prevProps.multiplierValue === nextProps.multiplierValue});

interface MultiplierProps {
    multiplierValue: number;
    visible: boolean;
}

export default Multiplier;