

export const GameRules = (playerName: string) =>
<div>
    <h3>Crash</h3>
    <p>RTP: 96.02%</p>
    <p>Begin by selecting a bet amount using the bet button, and then placing a bet during the "place bets" phase of the game using one or both of the the "bet" buttons.</p>
    <p>Once the game begins, the space ship will take flight and the multiplier will begin counting upwards.</p>
    <p>While playing this game you will have a player name that is unique to you, and this name will be seen when you bet and collect.</p>
    <p>Your player name is:</p>
    <strong>{ playerName }</strong>
    <h3>The game</h3>
    <em>Collect your winnings at any time or wait for the multiplier to increase even more for a chance to increase your winnings--but remember that the multiplier can stop (or crash) at any point.</em>
    <p>If the multiplier stops before you collect your winnings, you will lose your stake.</p>
    <p>In this crash game, you can place two individual bets.</p> 
    <p>You can collect your winnings by clicking on collect.</p>
    <p>The maximum multiplier that can be reached is 5,000x</p>
    <h3>Game rules</h3>
    <ul>
        <li>To begin, during the place bet phase, select a bet using the Bet button</li>
        <li>Press one of the two bet buttons to make a bet</li>
        <li>You can now optionally change your bet amount, or keep your bet amount the same, and make a second bet by pressing the other bet button</li>
        <li>Once the multiplier begins to count up, you can collect on a bet by using one or both collect buttons at any time</li>
    </ul>

    <p>A list of previous results can be seen above the betting interface.</p>

    <h3>Interruptions</h3>
    <p>
        In the event of an interruption your bets will be auto-collected, the result of the auto-collection can be seen in the Play History section of the menu. If the round has not yet started, your bet will be refunded.
    </p>

    <h3>Latency</h3>
    <p>High latency and ping can affect your ability to communicate betting and collecting to our servers. If your bets and collects are taking a long time (more than three seconds) to appear in the bet/collect list, you may be experiencing issues with latency.</p>
    <p>If you are playing on a fixed internet connection, ensure that it is stable enough by consulting with your service provider. If you are playing using mobile data (4G/5G) ensure that you are in a location that is receiving a strong mobile data connection.</p>
    <p>It is not recommended to play on a connection with high latency, as this can affect your ability to collect at the desired time.</p>
</div>;
