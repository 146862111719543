import { useEffect, useState } from 'react';

import "@lindar-joy/goosicorn-quickies-library/lib/css/style.css";
import './App.css';
import BGLayer1 from "./assets/background/layer1-stars.png";
import BGLayer2 from "./assets/background/layer2-planets.png";
import BGLayer3 from "./assets/background/layer3-planets.png";
import BGLayer4 from "./assets/background/layer4-planets.png";
import { Button, CurrencyFormat, FadeAudio, Game, GetCurrencySymbol, Icon, LoadAudio, PlayAudio, Timeout, emitGameAction, useBfrStatusListener, useCommonBalanceListener, useCommonConnectionListener, useCommonErrorListener, useGameEventListener, useUiDisableListener } from "@lindar-joy/goosicorn-quickies-library";

import { Stomp } from '@stomp/stompjs';
import { GameHistoryRenderer } from './GameHistoryRenderer';
import MultiplierBackground from './components/MultiplierBackground';
import SpaceShip from './components/SpaceShip';
import Splash from './components/Splash';
import TakeOffTimer from './components/TakeOffTimer';
import { GAME_STATE, IGamePlayEvent, IResults, IShowEvent } from './events/showEvent';
import { GameRules } from './gameRules';
import {
    Background, BetsContainer,
    PlayerCount,
    PreviousResults
} from './styles';

import BetLine from './components/BetLine';
import ParallaxBackground from './components/ParallaxBackground';
import { styled } from 'styled-components';
import { IBetEvent } from './events/betEvent';

import packageJSON from "../package.json"
import { ICollectEvent } from './events/collectEvent';
import { useShipLoadListener } from './components/SpaceShip';
import Multiplier from './components/Multiplier';

interface IPublicBet {
    username: string;
    bet: number;
}

interface IPublicWin {
    multiplier: number;
    amount: number
    username: string;
}

const StyledButton = styled(Button)`
    width: 80px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    font-variant-numeric: tabular-nums;
`;

const IconContainer = styled(Icon)`
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 18px;
    padding: 2px;
`;

let currentState: string;
let betAId: string;
let betBId: string;
let publicBetsBacking: IPublicBet[] = [];
let multiplierBacking: number = 0;
let publicWinsBacking: IPublicWin[] = [];
let audioPlaying = false;

let betACollected: boolean;
let betBCollected: boolean;
let betABacking: number;
let betBBacking: number;
let crashedFlag: boolean;

let sessionLossesBacking: number = 0;
let sessionWinningBacking: number = 0;

let musicPlaying = false;

function App() {

    let [loading, setLoading] = useState<boolean>(true);
    let [betAmount, setBetAmount] = useState<number>(0);
    let [bets, setBets] = useState<number[]>([10, 50, 100, 500, 1000]);
    let [sessionWinnings, setSessionWinnings] = useState<number>(0);
    let [sessionLosses, setSessionLosses] = useState<number>(0);
    let [freePlay, setFreePlay] = useState<boolean>(false);
    let [ goosicornUsername, setGoosicornUsername ] = useState<string>();
    let [initialising, setInitialising] = useState<boolean>(true);
    let [balance, setBalance] = useState<number>(0);
    let [balanceBacking, setBalanceBacking] = useState<number>(0);
    let [bfrInUse, setBfrInUse] = useState<boolean>(false);
    let [bfrCount, setBfrCount] = useState<number>(0);
    let [showGame, setShowGame] = useState<boolean>(false);
    let [ betInProgress, setBetInProgress ] = useState<boolean>(false);
    let [ broadcasterConnected, setBroadcasterConnection ] = useState<boolean>(false);
    let [ currentMultiplier, setCurrentMultiplier ] = useState<number>(0);
    let [ stateTakeOffTimer, setStateTakeOffTimer ] = useState<number>(0);
    let [ currentGameEngineRoundId, setCurrentGameEngineRoundId ] = useState<string>();
    let [ currentStateBacking, setCurrentStateBacking] = useState<string>();
    let [ thereArePlacedBets, setThereArePlacedBets ] = useState<boolean>();
    let [ publicBets, setPublicBets ] = useState<IPublicBet[]>([]);
    let [ publicWins, setPublicWins ] = useState<IPublicWin[]>([]);
    let [ betA, setBetA ] = useState<number>();
    let [ betB, setBetB ] = useState<number>();
    let [ uiDisabled, setUIDisabled ] = useState<boolean>();
    let [ resultCollection, setResultCollection ] = useState<IResults[]>();
    let [ errorShowing, setErrorShowing ] = useState<boolean>();
    let [ betAPlaced, setBetAPlaced ] = useState<boolean>();
    let [ betBPlaced, setBetBPlaced ] = useState<boolean>();
    let [ shipLoaded, setShipLoaded ] = useState<boolean>();

    const connectStomp = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const connectorUrl = urlParams.get("server");
        const gameId = urlParams.get("gameId");

        if (!connectorUrl) {
            throw new Error(`Missing connector URL`);
        }
        const connectorParts = connectorUrl.split(":");
        let broadcasterUrl = "";
        if (connectorParts[0] === "https") {
            broadcasterUrl = "wss://" + connectorParts[1].split("/")[2] + "/multigame";
        } else {
            broadcasterUrl = "ws://" + connectorParts[1] + ":9001/multigame";
        }

        const stompClient = Stomp.over(() => new WebSocket(broadcasterUrl));
        stompClient.reconnect_delay = 5000;
        //We do not want the stomp client showing all the events in the console
        stompClient.debug = (msg) => {}

        stompClient.onStompError = ( (error) => {
            console.log("Socket closed by server")
            throw new Error(error.body);
        })

        stompClient.connect({}, () => {
            setBroadcasterConnection(true);
            stompClient.subscribe(`/topic/${gameId}`, (frame) => {
                const event = JSON.parse(frame.body);
                const gamePlay = event.gamePlay as IGamePlayEvent;
                if (event.type === "common:public-bet") {
                    const gameAction = JSON.parse(event.gameAction);
                    publicBetsBacking.unshift({bet: event.amount, username: event.goosicornUsername});
                    setPublicBets(publicBetsBacking);
                }

                if (event.type === "common:bet") {
                    const gameAction = JSON.parse(event.gameAction);
                    publicBetsBacking.unshift({bet: event.amount, username: event.goosicornUsername});
                    setPublicBets(publicBetsBacking);
                }

                if (event.type === "common:public-win") {
                    const gameAction = JSON.parse(event.gameAction);
                    const gameEvent = JSON.parse(event.gameEvents[0]);
                    publicWinsBacking.unshift({amount: gameEvent.winnings, multiplier: gameEvent.multiplier * 100, username: event.goosicornUsername});
                    setPublicWins(publicWinsBacking);
                }
                if (gamePlay) {
                    if (event.type === "crash:update-gameplay") {
                        if (gamePlay.timer) {
                            setStateTakeOffTimer(10 - gamePlay.timer / 1000);
                        }

                        if (gamePlay.resultCollection) {
                            setResultCollection(gamePlay.resultCollection);
                        }
                    }

                    if (gamePlay.enginePhase == GAME_STATE.PLACE_BET) {
                        setCurrentGameEngineRoundId(gamePlay.result.engineRoundId);
                        
                    }
                    
                    if (gamePlay.result && !Number.isNaN(gamePlay.result.flyingDistance)) {
                        setCurrentMultiplier(gamePlay.result.flyingDistance);
                        multiplierBacking = gamePlay.result.flyingDistance;
                    }

                    currentState = gamePlay.enginePhase;
                    setCurrentStateBacking(currentState);

                    if (gamePlay.enginePhase == GAME_STATE.CRASHED) {
                        setThereArePlacedBets(false);
                        setBetAPlaced(false);
                        setBetBPlaced(false);
                        
                        if (!crashedFlag) {
                            if (betACollected === false && betABacking) {
                                sessionLossesBacking += betABacking;
                            }
    
                            if (betBCollected === false && betBBacking) {
                                sessionLossesBacking += betBBacking;
                            }
                            setSessionLosses(sessionLossesBacking);
                            betACollected = false;
                            betABacking = 0;
                            betBCollected = false;
                            betBBacking = 0;
                        }
                        crashedFlag = true;
                    } else {
                        crashedFlag = false;
                    }
                }
            })
        }, (error) => {
            console.error(error);
        })
    };
    
    useEffect(() => {
        if (currentStateBacking === GAME_STATE.PLACE_BET || (!musicPlaying && currentStateBacking === GAME_STATE.FLYING)) {
            PlayAudio("Music", true, 0.25);
            musicPlaying = true;
        } else if (currentStateBacking === GAME_STATE.CRASHED) {
            ( async () => {
                await FadeAudio("Music", 0.25, 0, 100);
                
            })();
            PlayAudio("Ship Crash", false, 0.35);
        }
        
    }, [  currentStateBacking ]);

    useBfrStatusListener(bfrStatus => {
        setBfrInUse(bfrStatus.inUse);
        setBfrCount(bfrStatus.bfrCount);
    });

    useCommonErrorListener(() => {
        setErrorShowing(true);
    });

    useCommonBalanceListener( commonBalanceData => {
        setBalanceBacking(commonBalanceData.amount);
        setBalance(commonBalanceData.amount);
    });

    useCommonConnectionListener( connectionData => {
        setGoosicornUsername(connectionData.goosicornUsername);
        setBalance(balanceBacking);
    });

    useShipLoadListener(() => setShipLoaded(true));
    
    useEffect(() => {
        if (!shipLoaded) {
            return;
        }
        (async () => {
            await LoadAudio([
                "music", "sfx"
            ]).catch( error => console.error(error));
            
            
            setShipLoaded(true);
            setTimeout(() => {
                setLoading(false);    
                setShowGame(true);
                connectStomp();
            }, 4000);
        })();
    }, [ shipLoaded ]);

    useUiDisableListener( ui => {
        setUIDisabled(ui.disabled);
    });

    useEffect(() => {
        switch(currentState) {
            case GAME_STATE.FLYING:
                setPublicBets([]);
                publicBetsBacking = [];
            break;

            case GAME_STATE.PLACE_BET:
                setPublicWins([]);
                publicWinsBacking = [];
            break;
        }
    }, [ currentState ]);

    useGameEventListener((event) => {
        switch (event.type) {
            case "crash:show":
                const showEvent = event as IShowEvent;
                setInitialising(false);
                currentState = showEvent.currentState;
                setBetAmount(showEvent.defaultBet);
                setThereArePlacedBets(showEvent.thereArePlacedBets);
                setCurrentStateBacking(currentState);
                
                setBalance(balanceBacking);
            break;
            case "crash:collect":
                const collectEvent = event as ICollectEvent;
                
                if (collectEvent.betId === betAId && betA && balanceBacking >= betAmount) {
                    setSessionWinnings(sessionWinnings + (collectEvent.winnings - betA));
                }

                if (collectEvent.betId === betBId && betB && balanceBacking >= betAmount) {
                    setSessionWinnings(sessionWinnings + (collectEvent.winnings - betB));
                }
                
            break;
            case "crash:bet":
                const betEvent = event as IBetEvent;
                if (betEvent.position === 0) {
                    betAId = betEvent.betId;
                }

                if (betEvent.position === 1) {
                    betBId = betEvent.betId;
                }
            break;
        }
    });

    const playText = (position: number) => {
        if (currentStateBacking === GAME_STATE.FLYING) {
            if (position === 0 && betAPlaced && betA) {
                return `COLLECT (${GetCurrencySymbol()}${(betA * (currentMultiplier / 100) / 100).toFixed(2)})`;
            } else if (position === 1 && betBPlaced && betB) {
                return `COLLECT (${GetCurrencySymbol()}${(betB * (currentMultiplier / 100) / 100).toFixed(2)})`;
            } else {
                if (position === 0) {
                    if (betACollected) {
                        return `Collected`;
                    }
                }

                if (position === 1) {
                    if (betBCollected) {
                        return "Collected";
                    }
                }

                return `Waiting...`;
            }
        } else {
            if (position === 0 && betAPlaced) {
                return "Waiting...";
            } else if (position === 1 && betBPlaced) {
                return "Waiting...";
            } else {
                return bfrInUse ? `BET ${CurrencyFormat(betAmount, true)} (${bfrCount} Free Plays)` : `BET ${CurrencyFormat(betAmount, true)}`;
            }
        }
    }

    const placeBet = (usingBFRBalance: boolean, position: number) => {
        
        if (balanceBacking >= betAmount && !usingBFRBalance) {
            setBalance(balanceBacking - betAmount);
        }

        setFreePlay(usingBFRBalance);
        setThereArePlacedBets(true);
        emitGameAction({
            type: 'crash:bet',
            body: {
                position: position,
                engineRoundId: currentGameEngineRoundId,
                bet: betAmount
            }
        })
    }

    const SecondaryBetButton = <StyledButton disabled={errorShowing || currentStateBacking === GAME_STATE.CRASHED ||
        (currentStateBacking === GAME_STATE.FLYING && !betBPlaced) ||
        (currentStateBacking === GAME_STATE.PLACE_BET && (betBPlaced || stateTakeOffTimer < 1.5))
        || initialising || uiDisabled} onClick={async () => {
            if (currentState === GAME_STATE.FLYING) {
                setBetBPlaced(false);
                const collect = {
                    type: 'crash:collect',
                    body: {
                        betId: betBId,
                        engineRoundId: currentGameEngineRoundId
                    }
                };
                emitGameAction(collect);
                betBCollected = true;
            } else {
                setBetInProgress(true);
                setBetBPlaced(true);
                betBCollected = false;
                setBetB(betAmount);
                betBBacking = betAmount;
                placeBet(false, 1);
                await Timeout(1200);
                setBetInProgress(false);
            }
            
        }} text={playText(1)} size="md" primary={true} ></StyledButton>;
    const parallaxEffect = currentStateBacking !== GAME_STATE.FLYING;
    return (
        <div>
            <Background/>
            <ParallaxBackground animationPaused={parallaxEffect} backgroundImage={BGLayer1} rate={16} frequency={1}/>
            <ParallaxBackground animationPaused={parallaxEffect} backgroundImage={BGLayer2} rate={12} frequency={1}/>
            <ParallaxBackground animationPaused={parallaxEffect} backgroundImage={BGLayer3} rate={8} frequency={2}/>
            <ParallaxBackground animationPaused={parallaxEffect} backgroundImage={BGLayer4} rate={12} frequency={3}/>
            <SpaceShip state={currentStateBacking||""}/>
            { !loading && <div>
                <Game
                    showAdditionalGameRules={ false }
                    gameVersion={ packageJSON.version }
                    gameAreaAutoScale={false}
                    gameHistoryDetailView={GameHistoryRenderer}
                    balance={balance}
                    gameName="Crash"
                    bets={bets}
                    bet={betAmount || 100}
                    rightSideHUD={ SecondaryBetButton }
                    disablePlay={(currentStateBacking === GAME_STATE.PLACE_BET && (betAPlaced || stateTakeOffTimer < 1.5)) || currentStateBacking === GAME_STATE.CRASHED || (currentStateBacking === GAME_STATE.FLYING && !betAPlaced)}
                    disableMenuOpen={false}
                    transparentGameArea={true}
                    winnings={ sessionWinnings - sessionLosses }
                    gameRulesContent={GameRules(goosicornUsername || "")}
                    playText={playText(0)}
                    showPlayIcon={false}
                    disableBetChange={currentStateBacking !== GAME_STATE.PLACE_BET}
                    disableHUD={ 
                        initialising
                    }
                    
                    onBetSelect={ bet => {
                        setBetAmount(bet);
                    }}
                    gameAreaTopMargin={0}
                    onPlay={ async usingBFRBalance => {
                        if (currentState === GAME_STATE.FLYING) {
                            // collect
                            
                            setBetAPlaced(false);
                            const collect = {
                                type: 'crash:collect',
                                body: {
                                    betId: betAId,
                                    engineRoundId: currentGameEngineRoundId
                                }
                            };
                            emitGameAction(collect);
                            betACollected = true;
                        } else {
                            setBetInProgress(true);
                            // bet
                            setBetAPlaced(true);
                            betACollected = false;
                            setBetA(betAmount);
                            betABacking = betAmount;
                            placeBet(usingBFRBalance, 0);

                            await Timeout(1200);

                            setBetInProgress(false);
                        }
                    }}
                >
                    { resultCollection && <PreviousResults> {
                            // @ts-ignore
                            resultCollection.map( (result, index) => <div style={{fontSize: index === resultCollection.length - 1 ? "14px" : "10px", padding: "3px", backgroundColor: "var(--primary)", borderRadius: "4px"}}>{(result.flyingDistance / 100).toFixed(2)}X</div> )}
                        </PreviousResults> 
                    }
                    { publicBets.length > 0 && <PlayerCount><IconContainer size="sm" type="avatar" />{publicBets.length}</PlayerCount>}
                    { currentState == GAME_STATE.PLACE_BET && <TakeOffTimer takeOffTime={stateTakeOffTimer} />}
                    <Multiplier multiplierValue={currentMultiplier} visible={(currentState == GAME_STATE.FLYING || currentState == GAME_STATE.CRASHED)}/>
                </Game>
                    
                { publicBets.length > 0 && currentState == GAME_STATE.PLACE_BET && <BetsContainer>
                    {
                        publicBets.filter(bet => bet.username === goosicornUsername).sort( (betA, betB) => betB.bet - betA.bet).map((publicBet, index) => (
                        <BetLine key={ index } username={publicBet.username} isPlayer={ true } content={CurrencyFormat(publicBet.bet)}></BetLine>
                    ))}

                    {
                        publicBets.filter(bet => bet.username !== goosicornUsername).sort( (betA, betB) => betB.bet - betA.bet).map((publicBet, index) => (
                        <BetLine key={ index } username={publicBet.username} isPlayer={ false } content={CurrencyFormat(publicBet.bet)}></BetLine>
                    ))}
                </BetsContainer>
                }
                { publicWins.length > 0 && (currentState == GAME_STATE.FLYING || currentState == GAME_STATE.CRASHED) && <BetsContainer>
                    {publicWins.filter( win => win.username === goosicornUsername).sort( (winA, winB) => winB.amount - winA.amount ).map((publicWin, index) => (
                        <BetLine key={ index } username={publicWin.username} isPlayer={ true } content={`${publicWin.multiplier/100}x / ${CurrencyFormat(publicWin.amount)}`}></BetLine>
                    ))}
                    {publicWins.filter( win => win.username !== goosicornUsername).sort( (winA, winB) => winB.amount - winA.amount ).map((publicWin, index) => (
                        <BetLine key={ index } username={publicWin.username} isPlayer={ false } content={`${publicWin.multiplier/100}x / ${CurrencyFormat(publicWin.amount)}`}></BetLine>
                    ))}
                </BetsContainer>
                }
            </div>
            }
            { !showGame && <Splash />}
        </div>
        
    );
}

export default App;
